import KBText from '@/components/KBText'

import BodySpacer from '@/components/LayoutComponents/BodySpacer'

import { MenuWithPlanLevel } from '@/constants/menuWithPlanLevel'

import { useAuthentication } from '@/contexts/AuthContext'

import { useLangContext } from '@/contexts/LangContext'

import useGlobalLoading from '@/hooks/useGlobalLoading'

import * as api from '@/services/api'

import { configApiToken } from '@/services/request'

import { clearLocalStorage } from '@/utils/localStorage'

import { checkAppInstalled, redirectAfterLogin, tr } from '@/utils/utils'
import { app, authentication } from '@microsoft/teams-js'

import { toggleTheme } from '@zougt/vite-plugin-theme-preprocessor/dist/browser-utils'
import { Button, Layout, notification, Space } from 'antd'
import dayjs from 'dayjs'
import { useCallback, useEffect, useState, Suspense } from 'react'
import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'

import h5Img from '../assets/h5Img.png'

import styles from './BasicLayout.module.less'

import { useThemeContext } from '@/contexts/ThemeContext'

import PageLoading from '@/components/PageLoading'

function AuthenticatedLayout(props: any) {
  const { setIsDark } = useThemeContext()
  const {
    currentUser,
    space,
    setSpace,
    setDepartments,
    setPermissions,
    setFollowingUsers,
    setSchedules,
    authenticated,
    login,
    setSpaceSettings,
    userSettings,
    setUserSettings,
    alertMsg,
    getPlanCanUse,
    permissions = {},
  } = useAuthentication()

  const navigate = useNavigate()
  const topAlert = !!alertMsg == true
  const [loaded, setLoaded] = useState(false)
  const [isShowH5Alert, setIsShowH5Alert] = useState(false)
  const { lang, setLang } = useLangContext()
  const { search, pathname } = useLocation()
  const [urlParams] = useSearchParams()
  const [allPermissions, setAllPermissions] = useState([])
  const userToken = urlParams.get('third_user_token') || urlParams.get('token') // Goodman单点登录
  const redirectUrl = urlParams.get('redirectUrl')
  // 带上token跳转过来的redirect
  if (redirectUrl) {
    sessionStorage.setItem('redirectUrl', decodeURIComponent(redirectUrl))
  }

  // const action = urlParams.get("action")
  const code = urlParams.get('code')

  const from = urlParams.get('from') // from==[teams|rwork]

  const isInTeams = !!sessionStorage.getItem('inTeams')

  const isFromTeam = from === 'teams' || isInTeams

  const isMobile = window.innerWidth <= 767

  // 华润
  if (from === 'rwork' && !currentUser?.jwt_token) {
    let _href = `?redirect_uri=${encodeURIComponent(
      `${import.meta.env.VITE_URL_SCHEME}://${
        import.meta.env.VITE_API_BASE_URL
      }/feishus/rwork_callback${search}`
    )}&app_id=${urlParams?.get('app_id') || ''}&state=ly`
    _href = `https://open.rwork.crc.com.cn/open-apis/authen/v1/index${_href}`
    window.location.href = _href
    return
  }
  if (urlParams?.get('from') === 'feishu' && !currentUser?.jwt_token) {
    let _href = `?redirect_uri=${encodeURIComponent(
      `${import.meta.env.VITE_URL_SCHEME}://${
        import.meta.env.VITE_API_BASE_URL
      }/feishus/callback${search}`
    )}&app_id=${urlParams?.get('app_id') || ''}&state=ly`
    _href = `https://open.feishu.cn/open-apis/authen/v1/index${_href}`
    window.location.href = _href
    return
  }

  // 判断是不是海康萤石云

  if (code) return api.hiKvisionLogin(code)

  useEffect(() => {
    setTheme(
      sessionStorage.getItem('resetTheme') ||
        localStorage.getItem('theme') ||
        'default'
    )
    const plat = navigator.userAgent.match(
      // 判断不同端
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    )

    localStorage.setItem('isH5', !!plat)
    if (userToken) {
      // if having token, then redirect or login
      if (isMobile && !isFromTeam) {
        window.location.replace('/appLink')
        // window.location.href = `https://h5.woxday.com/#/home?token=${userToken}`
        return null
      }
      // 第三方登录, 获取token
      // 判断是不是企业微信登录
      // return api.qiyeWechatLogin(userToken);
      clearLocalStorage()
      api
        .login({
          user_token: userToken,
          includes: 'locations',
        })
        .then(({ response }: any) => {
          login(response)

          // setLang(true);
          // loadUserData();
          redirectAfterLogin(response)
        })
        .catch((e: any) => {
          notification.error({
            message: e.message,
          })
          redirectToLogin()
        })
    } else if (currentUser?.jwt_token) {
      getTeamsContext()
      configApiToken(currentUser.jwt_token)
      loadUserData()
    } else if (!isInTeams) {
      // no jwt token, redirect to login
      redirectToLogin()
    }
  }, [currentUser?.id])

  const redirectToLogin = () => {
    const href = window.location.pathname + window.location.search
    const ssoProvider = urlParams.get('sso')

    if (isMobile && !isFromTeam) {
      if (userToken) {
        window.location.replace('/appLink')
        // window.location.href = `https://h5.woxday.com/#/home?token=${userToken}`
        return null
      }
      if (urlParams.get('sso') === 'azure') {
        // 跳转后端SSO
        window.location.href = import.meta.env.VITE_THIRD_PARTY_LOGIN_AMAZON
        return null
      }
      window.location.replace('/appLink')
      // window.location.href = `https://h5.woxday.com/#/home`
      return null
      // redirect to sso if needed
    }
    if (isFromTeam) {
      navigate('/teams_login', { state: { from: href } })
    } else if (!authenticated && isFromTeam) {
      setTimeout(() => {
        navigate('/teams_login', { state: { from: href } })
      }, 200)
    } else {
      if (ssoProvider === 'azure') {
        window.location.href = import.meta.env.VITE_THIRD_PARTY_LOGIN_AMAZON
        return
      }
      navigate(`/login?sso=${ssoProvider}`, {
        state: { from: href },
      })
    }
  }

  // 设置主题
  const setTheme = (theme: any) => {
    // default
    if (theme === 'default' || theme === 'theme-default' || !isFromTeam) {
      toggleTheme({
        scopeName: 'theme-default',
      })
      setIsDark(false)
      localStorage.setItem('theme', 'theme-default')
    } else {
      // dark contrast
      toggleTheme({
        scopeName: 'theme-dark',
      })
      setIsDark(true)
      localStorage.setItem('theme', 'theme-dark')
    }
  }

  // 获取用户当前信息和权限，全局使用
  const loadUserData = () => {
    if (!currentUser?.jwt_token) {
      redirectToLogin()
      return
    }
    setLoaded(false)

    Promise.all([
      api.getUser({
        includes: 'desks,work_status,user_groups,department,manage_departments',
      }),
      api.getSchedule({
        start_at: dayjs().startOf('day'),
        end_at: dayjs().endOf('day'),
        state: 'active_not_end',
        page: 1,
        per_page: 1000,
        not_show_cancel: true,
      }),
      api.getUserPermissions(),
      api.getMemberFollows({
        includes:
          'desks,work_status,department,location,simple_info,assistants',
      }),
      api.getSettings({
        target_type: 'Space',
        target_id: space?.id,
        includes: 'general',
      }),
      api.getDepartments({
        show: 'tree',
      }),
      api.getSettings({
        target_type: 'User',
        target_id: currentUser.id,
        includes: 'settings',
        inherit: true,
      }),
      // api.getSpace({
      //   includes: 'plan_features'
      // })
    ])
      .then((res) => {
        // console.log("**** member info", res[0].response)
        const userInfo = res[0].response
        login(userInfo)
        setSchedules(res[1].response)
        setPermissions(res[2].response)
        setAllPermissions(res[2].response)
        setFollowingUsers(res[3].response)
        setSpaceSettings(res[4].response)
        setDepartments(res[5].response)
        setUserSettings(res[6].response)
      })

      .finally(() => {
        setLoaded(true)
      })
  }

  const getTeamsToken = async () => {
    await getTeamsContext()

    try {
      const auth_token = await authentication.getAuthToken()
      const herf = window.location.pathname + window.location.search

      // call Server API to exchange auth_token for our Token to silent login (SSO)
      const response = await api.teamsLogin({ auth_token }, auth_token)
      login(response)

      navigate(herf || '/users', { replace: true })
    } catch (err) {
      navigate('/teams_register', {
        replace: true,
        state: { isTeams: true, from },
      })
    } finally {
      setTimeout(() => {
        app.notifyAppLoaded()
      }, 200)
    }
  }

  const getTeamsContext = useCallback(async () => {
    try {
      await app.initialize()

      const context = await app.getContext()
      console.log('teams app 111111', context)
      const lang = context?.app?.locale || 'zh-CN'
      setLang(lang)

      // Learn more about 'app' namespace from the link below
      // https://learn.microsoft.com/microsoftteams/platform/tabs/how-to/using-teams-client-sdk?tabs=javascript%2Cmanifest-teams-toolkit#differentiate-your-app-experience
      if (['TeamsModern', 'Teams'].includes(context?.app?.host?.name)) {
        const themeNow = context?.app?.theme
        setTheme(themeNow)
        app.registerOnThemeChangeHandler((theme) => {
          setTheme(theme)
        })
      } else {
        // outlook or office??
        const themeNow = context?.app?.theme
        setTheme(themeNow)
        app.registerOnThemeChangeHandler((theme) => {
          setTheme(theme)
        })
      }
      app.notifySuccess()
      if (isFromTeam) {
        sessionStorage.setItem('inTeams', 'true') // inTeams is useful for us to check whether browser is in teams
      }
    } catch (e) {
      // alert(`Initialization Error: ${JSON.stringify(e.message)}` );
      // appInitializationFailed();
      app.notifyFailure({
        reason: app.FailedReason.Other,

        message: `Teams App initialization failed: ${e.message}`,
      })
    }
  })

  // do not remove this line. it is used to hide the global loading indicator
  useGlobalLoading(loaded)

  // console.log("*** loaded", loaded)
  // if (!loaded) {
  //   return <GlobalLoading />;
  // }

  useEffect(() => {
    if (isInTeams) {
      if (currentUser?.jwt_token) {
        return
      }

      getTeamsToken()
    }
  }, [isInTeams])

  const isH5 = localStorage.getItem('isH5') === 'true'
  const changeH5OpenType = (type: any) => {
    setIsShowH5Alert(true)
    localStorage.setItem('openH5Type', type)

    localStorage.setItem('isH5', true)
  }

  // TODO: taoh - move to DownloadApp.jsx
  const openH5Type = localStorage.getItem('openH5Type')
  // console.log(isH5, isShowH5Alert, openH5Type, isInTeams)
  if (isH5 && !isShowH5Alert && !openH5Type) {
    if (isInTeams) {
      changeH5OpenType('h5')

      return <></>
    }

    return (
      <Space
        size={0}
        className={styles.h5Box}
        direction="vertical"
        align="center"
      >
        <img width="212px" src={h5Img} />

        <BodySpacer size={16} />

        <KBText>{tr('space.openAppDesc')}</KBText>

        <BodySpacer size={40} />

        <Button
          type="primary"
          block
          onClick={() => {
            changeH5OpenType('app')
          }}
        >
          {tr('space.openApp')}
        </Button>

        <BodySpacer size={20} />

        <KBText
          className="gray_color"
          level={3}
          onClick={() => {
            changeH5OpenType('h5')
          }}
        >
          {tr('space.continueOpenWithBrowser')}
        </KBText>
      </Space>
    )
  }

  // 判断订阅权限，如果没有订阅则跳转到占位页面
  // const checkSubscriptionPlan = () => {
  const menuWithPlanLevel = MenuWithPlanLevel.filter(
    (menu: any) => menu.url === pathname
  )[0]
  if (menuWithPlanLevel) {
    const planStatus = getPlanCanUse(
      menuWithPlanLevel.feature,
      menuWithPlanLevel.level
    )
    if (!planStatus.canUse) {
      navigate('/admin/locations/subscriptions/noSubscription', {
        state: {
          menuWithPlanLevel,
          planStatus,
        },
      })
      return
    }
  }

  return (
    <Layout>
      <div>
        <div className={styles.content}>
          <Suspense fallback={<PageLoading />}>
            <Outlet />
          </Suspense>
        </div>
      </div>
    </Layout>
  )
}

export default AuthenticatedLayout
