// key: 订阅名称，必须和订阅模块名称相同：module.name， 如desk, lock, ...
import reactLazyWithRetry from '@fatso83/retry-dynamic-import/react-lazy'

import spaceRoutes from './routes/space.routes'

import locationRoutes from './routes/location.routes'

import userRoutes from './routes/user.routes'

import exceptionRoutes from './routes/exception.routes'

import testRoutes from './routes/testRoutes.routes'

import employeeRoutes from '@/routes/employeeUser.routes'

import { Navigate } from 'react-router-dom'
// import { lazy } from 'react'

import AuthenticatedLayout from './layouts/AuthenticatedLayout'

const AdminLayout = reactLazyWithRetry(() => import('./layouts/AdminLayout'))

const EmployeeLayout = reactLazyWithRetry(
  () => import('./layouts/EmployeeLayout')
)
const AppLinkPage = reactLazyWithRetry(
  () => import('./pages/AppLink/AppLinkPage')
)
import Exception404 from './pages/Exception/404'

const routes = [
  {
    path: '/',
    context: 'main',

    element: <AuthenticatedLayout />,
    permission: 'all',
    children: [
      {
        path: '/',
        index: true,
        hideInMenu: true,

        element: <Navigate to="/user" replace />,
      },
      {
        path: 'admin',
        context: 'admin',
        hideInMenu: true,

        element: <AdminLayout />,
        children: [
          {
            path: '',
            children: [
              {
                path: '',

                element: <Navigate to="/admin/space/dashboard" replace />,
              },
              // 分店 目录
              locationRoutes,
              // 空间 根目录
              spaceRoutes,
            ],
          },
        ],
      },
      {
        path: 'user',
        context: 'user',
        hideInMenu: true,

        element: <EmployeeLayout />,
        children: [
          // 用户 目录
          employeeRoutes,
        ],
      },
      // Exception 目录
      exceptionRoutes,
      {
        path: '*',

        element: <Exception404 />,
        permission: 'all',
      },
    ],
  },
  testRoutes,
  // User 登录注册
  userRoutes,
  {
    path: '/appLink',
    element: <AppLinkPage />,
  },
]

export default routes
